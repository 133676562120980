import React from 'react'
import * as notFoundStyles from '../styles/pageNotFound.module.css'
import { Link } from 'gatsby'

export default function NotFoundPage () {
  return (
    <div className={notFoundStyles.notFoundContainer}>
      <h1>Oh no, it’s a 404!</h1>
      <h2>Looks like this page has been swallowed up by a black hole. </h2>
      <Link
        className='primaryButton'
        to='/'>
        Go back Home
      </Link>
    </div>
  )
}
